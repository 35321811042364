<template>
  <div class="page-content">
    <div class="card">
      <div class="card-body" v-loading="loading.table">
        <div class="row align-items-center">
          <form class="search-form col-md-6" @submit.prevent="doSearch()">
            <div class="input-group border rounded-sm">
              <div class="input-group-prepend">
                <div class="input-group-text border-0 rounded-sm">
                  <FeatherIcon type="search" class="icon-md cursor-pointer" />
                </div>
              </div>
              <input v-model="search.search" type="text" class="form-control border-0 rounded-sm" id="searchForm" placeholder="Search here...">
            </div>
          </form>
          <div class="col-md-4"></div>
          <div class="col-md-2">
            <button class="btn btn-primary float-right" @click="addFlow">
              Add Flow
            </button>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 col-sm-12">
            <div class="table-responsive">
              <table class="table table-hover table-ordered">
                <thead>
                  <tr>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Flow name</span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Categories</span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Created at</span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Published at</span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>Status</span>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex align-items-center">
                        <span>#</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in list" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>{{ item.categories_str }}</td>
                    <td>{{ item.created_at_str }}</td>
                    <td>{{ item.published_at_str }}</td>
                    <td>
                      <b-badge :variant="item.status_color" class="p-1">{{ item.status }}</b-badge>
                    </td>
                    <td>
                      <el-tooltip class="box-item" effect="dark" content="Publish" placement="bottom-start">
                        <el-button class="p-2" @click="publish(item)" v-if="item.status == 'draft'" type="primary" icon="el-icon-upload"></el-button>
                      </el-tooltip>
                      <el-tooltip class="box-item" effect="dark" content="Response" placement="bottom-start">
                        <el-button class="p-2" @click="response(item)" v-if="item.status !== 'draft'" type="primary" icon="el-icon-s-claim"></el-button>
                      </el-tooltip>
                      <el-tooltip class="box-item" effect="dark" :content="item.status == 'draft' || item.status == 'published' ? 'Edit' : 'Detail'" placement="bottom-start">
                        <el-button class="p-2" @click="update(item)" type="warning" :icon="item.status == 'draft' || item.status == 'published' ? 'el-icon-edit' : 'el-icon-tickets'"></el-button>
                      </el-tooltip>
                      <el-tooltip class="box-item" effect="dark" content="Delete" placement="bottom-start">
                        <el-button class="p-2" @click="remove(item)" v-if="item.status == 'draft'" type="danger" icon="el-icon-delete"></el-button>
                      </el-tooltip>
                      <el-tooltip class="box-item" effect="dark" content="Archive" placement="bottom-start">
                        <el-button class="p-2" @click="archive(item)" v-if="item.status == 'published'" type="info" icon="el-icon-takeaway-box"></el-button>
                      </el-tooltip>
                      <el-tooltip class="box-item" effect="dark" content="Copy Flow" placement="bottom-start">
                        <el-button class="p-2" @click="copy(item)" type="info" icon="el-icon-copy-document"></el-button>
                      </el-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <el-empty v-if="totalRow == 0" description="No data"></el-empty>
        <b-pagination v-if="totalRow > per_page" v-model="currentPage" :total-rows="totalRow" :per-page="per_page"/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import popupErrorMessages from '@/library/popup-error-messages';
import flowsAPI from '../../../api/flows';

export default {
  name: 'FlowsList',
  metaInfo: {
    title: 'List Flows',
  },
  data() {
    return {
      loading: {
        table: false,
      },
      list: [],
      search: {
        page: this.$route.query.page || 1,
        search: this.$route.query.search || '',
        status: this.$route.query.status || '',
      },
      per_page: 20,
      totalRow: 0,
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        console.log(val);
        this.search.page = val;
        this.getList();
      },
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    addFlow() {
      this.$router.push('/flow/create');
    },
    doSearch() {
      this.page = 1;
      this.getList();
    },
    getList() {
      const options = this.search;
      this.loading.table = true;
      flowsAPI.get({
        workspace_id: this.activeWorkspace._id,
        options,
      }).then(async (res) => {
        this.loading.table = false;
        await popupErrorMessages(res);
        this.totalRow = res.data.count;
        this.list = res.data.rows.map((v) => {
          v.categories_str = v.categories.join(', ');
          ['created_at', 'published_at', 'archived_at'].forEach((date) => {
            v[`${date}_str`] = v[date] ? moment(v[date]).format('DD MMM YYYY, HH:mm') : '-';
          });
          if (v.status === 'draft') {
            v.status_color = 'warning';
          } else if (v.status === 'archived') {
            v.status_color = 'danger';
          } else if (v.status === 'published') {
            v.status_color = 'primary';
          } else {
            v.status_color = 'success';
          }
          return v;
        });
      }).catch(async () => {
        this.loading.table = false;
      });
    },
    async remove(dt) {
      this.$confirm(this.$t('flows.confirm.delete'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await flowsAPI.delete({
              flow_id: dt.id,
              workspace_id: this.activeWorkspace._id,
            }).then(async (response) => {
              instance.confirmButtonLoading = false;
              await popupErrorMessages(response);
              this.$message({
                message: this.$t('flows.success.delete'),
                type: 'success',
              });
              this.search = {
                search: this.$route.query.search || '',
                page: this.$route.query.page || 1,
                status: this.$route.query.status || '',
              };
              this.getList();
            });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      });
    },
    async archive(dt) {
      this.$confirm(this.$t('flows.confirm.archive'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await flowsAPI.archive({
              flow_id: dt.id,
              workspace_id: this.activeWorkspace._id,
            }).then(async (response) => {
              instance.confirmButtonLoading = false;
              await popupErrorMessages(response);
              this.$message({
                message: this.$t('flows.success.archive'),
                type: 'success',
              });
              this.search = {
                search: this.$route.query.search || '',
                page: this.$route.query.page || 1,
                status: this.$route.query.status || '',
              };
              this.getList();
            });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      });
    },
    async publish(dt) {
      this.$confirm(this.$t('flows.confirm.publish'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await flowsAPI.publish({
              flow_id: dt.id,
              workspace_id: this.activeWorkspace._id,
            }).then(async (response) => {
              instance.confirmButtonLoading = false;
              await popupErrorMessages(response);
              this.$message({
                message: this.$t('flows.success.publish'),
                type: 'success',
              });
              this.search = {
                search: this.$route.query.search || '',
                page: this.$route.query.page || 1,
                status: this.$route.query.status || '',
              };
              this.getList();
            });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      });
    },
    async copy(dt) {
      this.$confirm(this.$t('flows.confirm.duplicate'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await flowsAPI.duplicate({
              flow_id: dt.id,
              workspace_id: this.activeWorkspace._id,
            }).then(async (response) => {
              instance.confirmButtonLoading = false;
              await popupErrorMessages(response);
              this.$message({
                message: this.$t('flows.success.duplicate'),
                type: 'success',
              });
              this.search = {
                search: this.$route.query.search || '',
                page: this.$route.query.page || 1,
                status: this.$route.query.status || '',
              };
              this.getList();
            });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      });
    },
    update(dt) {
      this.$router.push(`/flow/update/${dt.id}`);
    },
    response(dt) {
      this.$router.push(`/flow/response/${dt.id}`);
    },
  },
};
</script>
